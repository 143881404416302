@import '../_shared/_shared'

#section
  display: flex
  flex-direction: column
  align-items: center
  margin-bottom: 1.5rem
  #showcase
    margin-top: 2rem
    display: grid
    grid-template-columns: 1fr 1fr
    grid-template-rows: 35rem 3.75rem 5.75rem 5rem
    grid-template-areas: ". ." "thumbs thumbs" "likes likes" "guarentees guarentees"
    position: relative
    background-color: $greyish
    border-radius: $wave-curve-height $wave-curve-height 0 0
    max-width: 100%
    #slider, #thumbContainer, #wave, #likes, #guarentees
      width: 100%
      height: 100%
      > :global(.swiper)
        width: 100%
        height: 100%
    #slider
      $padding-top: 1rem
      grid-area: 1 / 1 / 5 / 3
      padding-top: $padding-top
      .brand
        position: absolute
        top: 1.5rem - $padding-top
        right: 1.375rem
        img
          height: 5.5rem
          width: 5.5rem
          border-radius: 100%
    #thumbContainer
      z-index: 1
      grid-area: thumbs
      display: flex
      justify-content: center
      height: 1.65rem
      position: relative
      img
        border-radius: 100%
        aspect-ratio: 1 / 1  
      #previous, #next
        position: relative
        z-index: 1
        display: flex
        justify-content: center
        align-items: center
        background-color: $white
        border-radius: 100%
        height: 3.125rem
        width: 3.125rem
      #previous
        border-radius: 100% 0 0 100%
        left: 1.6875rem
      #next
        border-radius: 0 100% 100% 0
        right: 1.6875rem
      #sliderContainer
        background-color: $white
        width: 8.9375rem
        max-width: 8.9375rem
        margin: auto 0
        padding: .1875rem 0
        position: relative
        display: flex
        align-items: center
        justify-content: center

        :global(.swiper-slide)
          align-items: center
          justify-content: center
          .thumb
            display: flex
            align-items: center
            justify-content: center

        #thumbSlider
          $size: 2.75rem
          z-index: 2
          top: 0
          bottom: 0
          left: 0
          right: 0
          max-width: $size
          max-height: $size
          display: flex
          align-items: center
          justify-content: center
          border-radius: 100%
          border: .125rem solid white
          :global(.swiper-slide)
            align-items: center
          .thumb
            border-radius: 100%
            box-sizing: border-box
            img
              width: $size
              height: $size

        #backgroundThumbSlider
          $size: 1.75rem
          max-width: 63%
          z-index: 1
          max-height: $size
          border-radius: $size
          position: absolute
          top: 0
          bottom: 0
          margin: auto
          display: flex
          align-items: center
          justify-content: center
          .thumb
            img
              width: $size
              height: $size
    #wave
      grid-area: 4 / 1 / 4 / -1
      background-color: transparent
      &::before
        bottom: 4.0645rem
    #likes
      z-index: 3
      margin-left: 1.5rem
      grid-area: likes
      display: flex
      flex-direction: row
      align-items: center
      #likeList
        left: -.125rem
        $size: 1.75rem
        justify-content: center
        margin: 2rem 0 2rem 4.375rem
        display: flex
        .like
          margin-left: -.375rem
          height: $size
          width: $size
          border: .125rem solid $white
          box-sizing: content-box
          border-radius: 100%
          .text
            border-radius: 100%
            justify-content: center
            line-height: $size
            color: $white
            background-color: $brand-color
            font-size: 0.5625rem
            padding: .5625rem .4375rem .625rem .5rem
      #likeTitle
        text-align: left
        margin: 0 5.125rem 0 .9375rem
        width: 7.4375rem
        height: 1.75rem
        font-size: .6875rem
    #guarenteesSlider
      display: flex
      grid-area: guarentees
      background-color: $white
      border-radius: .25rem
      .guarantee
        margin: 0 0 0 1.8rem
        display: flex
        min-width: 90%
        background-color: $off-white
        .textBox
          display: flex
          flex-direction: column
          justify-content: center
          margin-left: 1.25rem
          text-align: left
        .img
          background-color: $brand-color
          border: .125rem solid $white
          border-radius: .25rem
          padding: .6rem
          margin: .6rem
        .header
          display: flex
          flex-direction: column
          font-weight: bold
        .text
          opacity: .5
          font-size: .875rem