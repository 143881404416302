@import '../_shared/_shared'

#section
  background-color: $off-white
  border-radius: 2.6875em
  padding: 0 2em 0 2em
  h3
    margin-bottom: .3125em
    line-height: 1.5em
    margin-top: 1.5625em
  h5
    margin-bottom: .625em
  div
    display: flex
    background-color: $white
    border-radius: 4.5625em
    margin-bottom: 3.25em
    input
      border-radius: 4.5625em
      margin-left: 1.625em
      right: 3.9em
      &:focus-visible, &::focus-visible
        appearance: none
        outline: 0
    a
      padding: .3em .5em .3em 1.9em
    img
      padding: .8em
      height: 3em
      width: 3em
      background-color: $brand-color
      border-radius: 2.6875em
