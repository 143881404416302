@import '../_shared/_shared'

#section
  margin-bottom: 2.75rem
  #title
    margin-bottom: 1.15rem
    font-size: 2.125rem
  #steps
    padding: 0 3rem 0
    flex-direction: column
    display: flex
    justify-content: center
    align-items: center
    .step
      .img
        align-items: center
        display: inline-block
        margin-bottom: .75rem
      &:not(:last-child)
        margin-bottom: 2rem
      &:last-child
        .header
          margin-bottom: .75rem
        .arrow
          display: none
      .arrow
        display: inline-block
        margin-top: 2rem
        padding: .8rem
        width: 2.125rem
        height: 2.125rem
        background-color: $off-white
        border-radius: 100%
  #button
    @extend .getStartedButton
