@use "sass:math"
@import './components/_shared/_shared'

$wide-phone: em_of($px: 414)

#main
  width: 100vw
  margin: auto
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden

  > *
    max-width: $wide-phone
    width: 100%
    &:not(:first-child)
      margin-top: em_of($px: 24)

#main
  font-size: 16px
  font-family: $body-font
  color: $black
  text-align: center

  h1
    font-family: $title-font
  h2, h3, h4, h5
    font-family: $subtitle-font
  h1
    font-size: 1.625rem
    line-height: 1.15em
    letter-spacing: -.0385em
  h2
    font-size: 1.75rem
    letter-spacing: -.05em
    font-weight: 600
  h3
    font-size: 1.5rem
    line-height: 1.25em
    letter-spacing: -.0625em
    font-weight: 600
  h4
    font-size: 1.5rem
    font-weight: 600
    line-height: 1.875em
    letter-spacing: -0.05em
  h5
    font-size: 1rem
    font-weight: normal
    line-height: 1.5em
  p
    font-size: 1rem
    opacity: .7
