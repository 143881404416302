@import '../_shared/_shared'

#section
  #titles
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 1.125rem 1.25rem
    h3
      font-size: em_of($px: 18)
      text-weight: 600
      letter-spacing: -.03125em

    > a
      font-size: .75rem
      line-height: 2

  #products
    padding-left: .425rem
    margin-bottom: 1.125rem
    .product
      overflow: visible
      display: flex
      flex-direction: column
      align-items: center
      width: 100%
      .imageContainer
        border-radius: 15px
        overflow: hidden
        background-color: #eff3f8
        height: 14rem
        margin: 0 .5em
        display: flex
        justify-content: center
        align-items: center
        img
          max-height: 100%
      .text
        margin: .625rem 0 .5rem
        padding: 0 1.25rem
        width: 100%
        text-align: start
        text-transform: uppercase
        .name
          font-size: .875em
          letter-spacing: 1px
          margin-bottom: .5em
        .price
          font-size: .75em
          font-weight: 600
          &::before
            content: '$'
      > a
        @extend .brandButton
        font-family: $subtitle-font
        font-weight: 600
        font-size: .625em
        text-transform: uppercase
