@import '../_shared/_shared'

#section
  margin-bottom: 1.375rem
  #text
    margin-bottom: .375rem
    #tiktok
      padding: 0 1em
      margin-bottom: 1.375rem
    #swipe
      margin-bottom: 4rem
  #reviews
    // overflow: visible
    margin: 1.5rem 0
    $active-size: 10.5em
    $ratio: .9
    $other-size: $active-size * $ratio
    height: $active-size
    :global(.swiper-wrapper)
      display: flex
      align-items: center
      :global(.swiper-slide)
        display: flex
        align-items: center
        .review
          aspect-ratio: 1 / 1
          position: relative
          padding: .375em
          transition: .5s ease
          transition-property: width, height
          display: grid
          align-items: center
          justify-items: center
          grid-template-columns: 1fr 27.5%
          grid-template-rows: 27.5% 1fr 24.5%
          grid-template-areas: ". checkmark" ". ." ". brand"
          font-size: 1rem * $ratio
          width: $other-size
          height: $other-size
          .image
            width: 100%
            height: 100%
            box-sizing: content-box
            object-fit: cover
            border: .25em solid white
            border-radius: 100%
            grid-area: 1 / 1 / -1 / -1
            background-color: white
          .brand, &::after
            display: flex
            justify-content: center
            align-items: center
          .brand
            grid-area: brand
            padding: .51875em .375em
            border-radius: 100%
            background-color: white
            object-fit: cover
          &::after
            grid-area: checkmark
            content: url(./checkmark.svg)
            background-color: #2485F4
            width: 2.5em
            height: 2.5em
            border-radius: 100%
            border: 3px solid $white
            padding: 1em .875em .96875em
          &::before
            content: ''
            z-index: -1
            position: absolute
            left: 0
            right: 0
            top: 0
            bottom: -1px
            background: linear-gradient(#F73606 48.44%, #F0C200 100%)
            border-radius: 100%
        &:global(.swiper-slide-active)
          .review
            font-size: 1rem
            width: $active-size
            height: $active-size
            &::before
              bottom: 0px
