@import '../_shared'

.wave
  position: absolute
  bottom: $wave-curve-height
  height: 0
  width: 100%
  background-color: $white
  z-index: 2

  &::before, &::after
    content: ''
    position: absolute
    display: block
  &::before
    height: $wave-curve-height * 1.2
    right: -1px
    bottom: $wave-curve-height * -0.2
    width: $wave-curve-height
    background-color: $white
    mask-image: url('./wave-corner-mask.svg')
  &::after
    height: calc($wave-curve-height + 1px)
    width: 100%
    border-top-left-radius: $wave-curve-height
    background-color: $white
