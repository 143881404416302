@import '../_shared/_shared'

#section
  display: flex
  align-items: center
  flex-direction: column
  padding: 0 1.125rem
  h3
    margin: 1.06rem 2.56rem 2.5625rem
  img
    margin-bottom: 1.2rem
    max-width: 73.5%
    aspect-ratio: 1 / 1
  #counter
    margin-top: 1.625rem
