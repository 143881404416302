@import '../_shared/_shared'

#section
  #titles
    display: flex
    justify-content: space-between
    align-items: center
    margin: 0 1.125rem 1rem
    h3
      font-size: em_of($px: 18)
      font-weight: 600
      letter-spacing: -.03125em
    p
      font-size: em_of($px: 10)
    b
      opacity: 1
      font-weight: 900
      color: #000000

  #categorySlider
    margin-bottom: 1.5rem
    margin-left: 1rem
    .slide
      height: 100%
      display: flex
      flex-direction: column
      justify-content: space-between
      margin: 0 .3125rem
      img
        box-sizing: content-box
        aspect-ratio: 1 / 1
        width: 5em
        background-clip: content-box
        border-radius: 100%
        padding: .25rem
        border: 1px solid $white
        background-color: white
      span
        margin-top: .25rem
        font-size: .875em
        line-height: 2
        font-weight: 500
        text-align: center

  #catalogSlider
    margin-left: .8125rem
    margin-bottom: 1rem
    :global(.swiper-slide)
      .slide
        width: 100%
        display: grid
        margin: 0 .3125rem
        grid-template-columns: 10rem 1fr
        grid-template-rows: 2.5rem 1fr 3.6875rem
        a
          grid-area: 1 / 1 / span 3 / span 2
          border-radius: .9375rem
          width: 100%
        .name
          grid-area: 3 / 1 / span 1 / span 1
          align-self: start
          justify-self: start
          margin-left: 1.5rem
          color: $white
          font-size: 1.375em
          font-weight: 700
          font-family: $subtitle-font
          line-height: 1.625rem
          letter-spacing: 1px
        .discount
          grid-area: 1 / 1 / span 1 / span 1
          align-self: end
          justify-self: center
          background-color: $brand-color
          font-weight: 500
          font-family: $subtitle-font
          color: $white
          font-size: .625em
          border-radius: 1em
          text-transform: uppercase
          padding: .2em .5em
          &::before
            content: url(./fire.svg)
            margin-right: .5em
