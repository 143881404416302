@import '../_shared/_shared'

$studio-white: #edeef3
$section-height: max(80vh, 40rem)
#section
  position: relative
  color: $white
  height: $section-height

  #title
    z-index: 1
    position: absolute
    left: 0
    right: 0
    text-transform: uppercase
    text-align: center
    transition: color .5s ease

    &::before
      z-index: -1
      content: ''
      position: absolute
      top: 0
      bottom: 0
      right: 0
      left: 0
      opacity: 1
      transition: opacity .5s ease
      background: linear-gradient(360deg, rgba(0, 0, 0, 0.0001) 0%, rgba(0, 0, 0, 0.53) 100%)

    &:not(.overlay)
      color: black
      &::before
        opacity: 0

    h1
      margin: em_of($px: 25) 1em 0
      span
        color: $brand-color
    > span
      margin-bottom: em_of($px: 22)
      font-size: .625em
      line-height: 3.8em
      letter-spacing: .1em
      font-weight: 600

  #heroes
    height: $section-height
    background-color: #edeef3
    .hero
      height: 100%
      width: 100%
      object-fit: cover
      object-position: bottom
      &:not(.overlay)
        padding-top: 8.5rem

  #bullets
    z-index: 2
    position: relative
    bottom: em_of($px: 23)
    display: flex
    justify-content: center
    align-items: center

    :global(.swiper-pagination-bullet)
      height: .25rem
      width: .25rem
      margin: 0 .3125rem
      &:global(.swiper-pagination-bullet-active)
        background-color: $brand-color
        height: .375rem
        width: .375rem

  #button
    @extend .getStartedButton
    position: absolute
    left: 0
    right: 0
    bottom: $wave-curve-height + rem_of($px: 28)
    // width: 68%
    margin: 0 16%
