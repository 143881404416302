@use "sass:math"

@function rem_of($size: 0, $px: 0)
  @return 1rem * ($size + math.div($px, 16))

@function em_of($size: 0, $px: 0)
  @return 1em * ($size + math.div($px, 16))

$brand-color: #fe5e33
$black: #0c0d34
$white: #ffffff
$off-white: #FAFAFA
$greyish: #EDEEF3

$title-font: LeagueSpartan, serif
$subtitle-font: Poppins, sans-serif
$body-font: HelveticaNeueInterface, sans-serif
$static: '/rnd'

$wave-curve-height: rem_of($px: 75)

.brandButton
  display: block
  width: 68%
  border-radius: em_of($px: 25)
  padding: em_of($px: 12)
  background-color: $brand-color
  color: $white
  font-weight: 500
  text-align: center
  user-select: none
  transition: none

  &:active
    top: unset !important
    transform: translate(0, 2px)

.getStartedButton
  @extend .brandButton
  display: inline-block
  padding: 1rem 0
  border-radius: 3.5rem
  width: calc(100% - 7rem)
  margin-top: 1.5625rem
