@import '../_shared'

.counter
  width: 100%
  .spacer
    display: inline-block
    width: .5rem
  .digitBackground
    display: inline-block
    background: linear-gradient(168.72deg, #FFD1C7 20.38%, #FDBB9E 83.37%), #C4C4C4
    margin: 0 .125rem
    .digit
      display: inline-block
      padding: .125rem .25rem 0rem
      font-family: $subtitle-font
      font-size: 1.875rem
      line-height: 1.1
      background: linear-gradient(168.72deg, #f53206 20.38%, #ff4d00 83.37%), #C4C4C4
      background-clip: text
      color: transparent
