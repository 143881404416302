.root
  display: flex
  max-width: 100%
  overflow: hidden
  z-index: 0
  :global(.swiper-wrapper)
    position: static
    display: flex
    height: 100%
    :global(.swiper-slide)
      padding: 0 !important
      display: flex
      justify-content: center
      > div
        height: 100%
