@import '../_shared/_shared'

#section
  display: flex
  flex-direction: column
  position: relative
  align-items: center

  > h3
    margin-bottom: .75em
    padding: 0 2.5em
  > p
    padding: 0 2em
    margin-bottom: em_of($px: 19)

  #demo
    $bottom-outcrop: em_of($px: 29.3)

    position: relative
    margin: 0 .75rem $bottom-outcrop
    #baseImage
      border-radius: 10px
    #avatar
      position: absolute
      max-width: 5.25rem
      background-color: $white
      border-radius: 0 10px 0 0
      bottom: -$bottom-outcrop
      left: -1px // pixel-rounding issue

  :global(.swiper)
    width: calc(100% - 3.375rem)
    overflow: hidden
    :global(.swiper-slide)
      .set
        margin: 0 .4375rem
        height: 14.5rem
        display: flex !important
        flex-direction: column
        justify-content: center
        > .imageContainer
          position: relative
          flex-grow: 1
          display: flex
          justify-content: center
          align-items: center
          &::after
            content: ''
            z-index: -1
            position: absolute
            background-color: #f9f9f9
            border-radius: 20px
            top: 40%
            right: 0
            bottom: -.5em
            left: 0
        .names
          margin-top: 1em
          width: 100%
          display: inline-flex
          flex-wrap: wrap
          justify-content: center
          align-items: center
          .brand
            color: $brand-color
            &::after
              content: '\00A0'
        // The size "Docas Summer Set" breaks on
        // as the longest text, so that all of them
        // wrap at the same time.
        @media (max-width: 366px)
          .names
            flex-direction: column
            .brand::after
              content: none

  #button
    @extend .getStartedButton
    position: relative
    margin: em_of($px: 49.5) 0 em_of($px: 36)
