@import '../_shared/_shared'
#section
  #ad
    display: grid
    margin-bottom: 1rem
    width: 100%
    grid-template-columns: repeat(5, 1fr)
    grid-template-rows: repeat(6, 1fr)
    video
      grid-area: 1  / 1 / span 6 / span 5
      height: 100%
      object-fit: cover
      object-position: center
    a
      @extend .brandButton
      grid-area: 5 / 2 / span 1 / span 3
      width: 100%
      align-self: center
      font-weight: 600
      z-index: 1
      margin-bottom: 1.775em

  #guarantees
    width: 100%
    display: flex
    justify-content: space-between
    padding: 0 .5em
    .guarantee
      display: flex
      flex-direction: column
      align-items: center
      flex-basis: 8em
      .svg
        height: 2em
        width: 2em
      .header
        font-size: em_of($px: 11)
        font-weight: 800
        margin-bottom: 0.5em
        width: 9em
      .text
        font-size: em_of($px: 9)
        text-align: center

  hr
    margin: 1.5rem 1.25rem 0
